@use '../utils' as *;

.tp-btn{
	display: inline-block;
	background-color: var(--tp-common-blue);
	color: var(--tp-common-white);
	height: 45px;
	line-height: 45px;
	border-radius: 30px;
	padding: 0 35px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: .5s;
	  & span {
		position: relative;
		z-index: 5;
		transition: .3s;
		transition-delay: .3s;
		color: var(--tp-common-white);
	  }
	  &:hover{
		& span{
			color: var(--tp-common-white);
			transition-delay: .1s;
		}
	}
}
.tp-btn-white{
	background-color: var(--tp-common-white);
	color: var(--tp-common-black);
	height: 45px;
	line-height: 45px;
	border-radius: 30px;
	padding: 0 35px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	overflow: hidden;
	transition: color .5s ease;
	transition-duration: .4s;
	& span{
		position: relative;
		z-index: 5;	
		transition: .5s;
		transition-delay: .1s;	
	}
	&:hover{
		color: var(--tp-common-black);
		transition-duration: .5s;
		& span{
			&.white-text{
				color: var(--tp-common-white);
			}
		}
	}
}
.tp-btn-orange{
	display: inline-block;
	background-color: var(--tp-common-orange);
	color: var(--tp-common-white);
	height: 36px;
	line-height: 36px;
	border-radius: 30px;
	padding: 0 30px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: .5s;
	& span {
		z-index: 99;
		position: relative;
		transition: .3s;
		transition-delay: .3s;
		color: var(--tp-common-white);
	}
	&:hover{
		& span{
			color: var(--tp-common-black);
			transition-delay: .1s;
		}
	}
}
.tp-btn-green-sm{
	display: inline-block;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-mont);
	height: 42px;
	line-height: 42px;
	padding: 0 30px;
	font-weight: 600;
	font-size: 15px;
	text-align: center;
	letter-spacing: -0.02em;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	transition: .5s;
	background: linear-gradient(180deg, #B1FF36 -23.00%, #1D863C 100%);
	box-shadow: 0px 2px 3px rgba(3, 22, 3, 0.2);
	border-radius: 10px;
	&:hover{
		box-shadow: 0px 8px 24px 0px rgba(25, 118, 41, 0.30);
		transform: translateY(-2px);
		color: var(--tp-common-white);	
	}
}
.tp-btn-green{
	display: inline-block;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-mont);
	height: 52px;
	line-height: 52px;
	padding: 0 30px;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	letter-spacing: -0.02em;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	transition: .5s;
	background: linear-gradient(180deg, #a364ff -23%, #6c35de 100%);
	box-shadow: 0px 2px 3px rgba(206, 166, 241, 1);
	border-radius: 10px;
	&:hover{
		box-shadow: 0px 8px 24px 0px rgba(25, 118, 41, 0.3);
		transform: translateY(-2px);
		color: var(--tp-common-white);	
	}
}
.tp-btn-blue-lg{
	display: inline-block;
	background: #5B6CFF;
	box-shadow: inset -2px -2px 2px rgba(7, 13, 29, 0.3);
	border-radius: 100px;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-urban);
	height: 60px;
	line-height: 61px;
	padding: 0 35px;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.02em;
	position: relative;
	display: inline-block;
	z-index: 1;
	overflow: hidden;
	transition: .3s;
	transition-duration: 1s;
	@media #{$xs}{
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
	}
	&.purple-bg{
		background-color: var(--tp-common-blue-4);
	}
	&.circle-effect{
		position: relative;
		&::after{
			background-color: var(--tp-common-black);
			border-radius: 95px;
			bottom: -100%;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: -100%;
			width: 100%;
			transform: scaleY(0);
			transform-origin: bottom;
			transition-duration: 1s;
			transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
			transition-timing-function: cubic-bezier(.14,0,0,1.01);
			will-change: transform;
			z-index: -1;
		}
	}
	&.circle-effect-black{
		position: relative;
		&::after{
			background-color: var(--tp-common-black);
			border-radius: 95px;
			bottom: -100%;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: -100%;
			width: 100%;
			transform: scaleY(0);
			transform-origin: bottom;
			transition-duration: 1s;
			transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
			transition-timing-function: cubic-bezier(.14,0,0,1.01);
			will-change: transform;
			z-index: -1;
		}
	}
	& span{
		position: relative;
		z-index: 5;	
		transition: .5s;
		color: var(--tp-common-white);	
	}
	&:hover{
		color: var(--tp-common-white);
		&.circle-effect{
			&::after{
				transform: scaleY(1);
				transform-origin: top;
			}
		}
		&.circle-effect-black{
			color: var(--tp-common-white);
			transition: .3s;
			&::after{
				transform: scaleY(1);
				transform-origin: top;
			}
		}
		&.purple-bg{
			background-color: transparent;
		}
		& span{
			&.text-color-black{
				color: var(--tp-common-black);
			}
		}
	}
}
.tp-btn-blue-sm{
	display: inline-block;
	background: #5B6CFF;
	box-shadow: inset -2px -2px 2px rgba(7, 13, 29, 0.3);
	border-radius: 100px;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-urban);
	height: 48px;
	line-height: 48px;
	padding: 0 35px;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.02em;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	transition: .5s;
	& span {
		position: relative;
		z-index: 5;
		transition: .3s;
		transition-delay: .3s;
		color: var(--tp-common-white);
	  }
	  &:hover{
		& span{
			color: var(--tp-common-white);
			transition-delay: .1s;
		}
	}
}
.tp-btn-border{
	display: inline-block;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-urban);
	border: 1px solid #5B6CFF;
	border-radius: 100px;
	height: 60px;
	line-height: 60px;
	padding: 0 35px;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.02em;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	&.purple-border{
		border-color: var(--tp-common-blue-4);
	}
	@media #{$xs}{
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
	}
	& span {
		position: relative;
		z-index: 5;
		transition: .7s;
		color: var(--tp-theme-1);
	  }
	  &:hover{
		border-color: var(--tp-common-black);
		& span{
			color: var(--tp-common-white);
		}
	}
}
.tp-btn-white-solid{
	display: inline-block;
	color: var(--tp-common-white);
	font-family: var(--tp-ff-urban);
	background: rgba(255, 255, 255, 0.24);
	backdrop-filter: blur(5px);
	border-radius: 100px;
	height: 36px;
	padding: 0 22px;
	font-weight: 700;
	font-size: 15px;
	line-height: 36px;
	text-align: center;
	letter-spacing: 0.02em;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	transition: .5s;
}
.tp-btn-yellow{
	display: inline-block;
	background: var(--tp-common-yellow-3);
	border-radius: 100px;
	height: 42px;
	padding: 0 32px;
	font-weight: 500;
	font-size: 16px;
	line-height: 42px;
	text-align: center;
	letter-spacing: 0.02em;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	transition: .5s;
	@media #{$lg}{
		height: 42px;
		padding: 0 20px;
		font-size: 14px;
	}
	& span {
		position: relative;
		z-index: 5;
		transition: .3s;
		color: var(--tp-common-black);
		&:hover{
			color: var(--tp-common-black);
		}
	}
	&.inner-color{
		background: #FFCE5A;
		color: var(--tp-common-black);
		&:hover{
			& span{
				color: var(--tp-common-white);
			}
		}
	}
}
.tp-btn-yellow-sm{
	display: inline-block;
	color: var(--tp-common-black);
	background: var(--tp-common-yellow-3);
	backdrop-filter: blur(5px);
	border-radius: 100px;
	height: 50px;
	padding: 0 24px;
	font-weight: 500;
	font-size: 17px;
	line-height: 50px;
	text-align: center;
	position: relative;
	overflow: hidden;
	display: inline-block;
	z-index: 1;
	transition: .5s;
}
.tp-btn-yellow-lg{
	display: inline-block;
	// color: var(--tp-common-black);
	color: #6c35de;
	background: #FFF;
	border-radius: 100px;
	height: 60px;
	padding: 0 32px;
	font-weight: 700;
	font-size: 16px;
	line-height: 60px;
	text-align: center;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: .5s;
	transition-duration: 1s;
	@media #{$xs}{
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
	}
	&::after{
		background-color: #000;
		border-radius: 100px;
		bottom: -100%;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: -100%;
		width: 100%;
		transform: scaleY(0);
		transform-origin: bottom;
		transition-duration: 1s;
		transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
		transition-timing-function: cubic-bezier(.14,0,0,1.01);
		will-change: transform;
		z-index: -1;
	}
	&:hover{
		background-color: transparent;
		color: #FFF;
		&::after{
			transform: scaleY(1);
			transform-origin: top;
		}
	}
	&.yellow-bg{
		background-color: #F8FF35;
	}
	
}
.disable{
	color:white;
	pointer-events: none;
	background-color: black;
	}
  
.tp-btn-yellow-border{
	height: 60px;
	line-height: 57px;
	padding-left: 38px;
	padding-right: 28px;
	border-radius: 100px;
	border: 2px solid white;
	color: white;
	font-size: 16px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	display: inline-block;
	font-weight: 600;
	font-size: 16px;
	transition: .3s;
	& i{
		padding-left: 60px;
		position: relative;
		z-index: 5;
		color: #6B14FA;
		font-size: 22px;
		transform: translateY(2px);
	}
	&::before{
		content: '';
		position: absolute;
		right: 0px;
		top: 0px;
		height: 100%;
		width: 60px;
		background: #FFF;
		z-index: -1;
		transition: .3s;
	}
	&:hover{
		color: #6B14FA;
		border: 2px solid #FFF;
		&::before{
			width: 100%;
			background: #FFF;
		}
	}
}
.tp-btn-service{
	height: 40px;
	line-height: 35px;
	border: 1.5px solid #FFF;
	color: var(--tp-common-yellow-3);
	border-radius: 20px;
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0px 30px;
	font-weight: 600;
	font-size: 15px;
	transition: 0.3s;
	&.text-lightt{
		color: #FFF;

		&:hover{
			color: #6B14FA;
		}
	}
	&:hover{
		background-color: #FFF;
		color: var(--tp-common-black);
		border-color: #FFF;
	}
}
.tp-btn-grey{
	height: 60px;
	line-height: 60px;
	color: var(--tp-common-black);
	border-radius: 30px;
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0px 35px;
	font-weight: 600;
	font-size: 16px;
	background: var(--tp-common-white);
	transition: .3s;
	transition-duration: 1s;
	overflow: hidden;
	@media #{$xs}{
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
	}
	&::after{
		background-color: var(--tp-common-black);
		border-radius: 100px;
		bottom: -100%;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: -100%;
		width: 100%;
		transform: scaleY(0);
		transform-origin: bottom;
		transition-duration: 1s;
		transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
		transition-timing-function: cubic-bezier(.14,0,0,1.01);
		will-change: transform;
		z-index: -1;
	}
	&:hover{
		background-color: transparent;
		color: var(--tp-common-white);
		&::after{
			transform: scaleY(1);
			transform-origin: top;
		}
	}
}
.tp-btn-purple{
	height: 40px;
	line-height: 38px;
	color: var(--tp-common-black);
	border-radius: 30px;
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0px 35px;
	font-weight: 600;
	font-size: 14px;
	background: #F7F9FB;
	transition: .3s;
	&:hover{
		color: var(--tp-common-white);
		background: #6B14FA;
	}
}
.tp-btn-purple-lg{
	height: 60px;
	line-height: 60px;
	color: var(--tp-common-white);
	background: #6B14FA;
	border-radius: 30px;
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0px 35px;
	font-weight: 600;
	font-size: 16px;
	transition: .3s;
	&:hover{
		color: var(--tp-common-white);
		background: #6B14FA;
	}
}
.tp-btn-yellow-solid{
	height: 60px;
	line-height: 60px;
	color: var(--tp-common-black);
	background: #DDFF7B;
	border-radius: 30px;
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0px 35px;
	font-weight: 600;
	font-size: 16px;
	transition: .3s;
	overflow: hidden;
	&.circle-effect{
		position: relative;
		&::after{
			background-color: var(--tp-common-white);
			border-radius: 95px;
			bottom: -100%;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: -100%;
			width: 100%;
			transform: scaleY(0);
			transform-origin: bottom;
			transition-duration: 1s;
			transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
			transition-timing-function: cubic-bezier(.14,0,0,1.01);
			will-change: transform;
			z-index: -1;
		}
	}
	&:hover{
		color: var(--tp-common-black);
		background: #DDFF7B;
		&.circle-effect{
			&::after{
				transform: scaleY(1);
				transform-origin: top;
			}
		}
	}
}
.tp-btn-hover {
	position: relative;
	z-index: 2;
	overflow: hidden;
	& b {
		position: absolute;
		width: 0px;
		height: 0px;
		background-color: var(--tp-common-black);
		border-radius: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all 1s ease;
		transition: all 1s ease;
		z-index: 1;
	}

	&:hover {
		& b {
			width: 400px;
			height: 400px;
			display: inline-block;
		}
	}

	&.alt-color {
		& b {
			background-color: var(--tp-theme-1);
		}
	}
	&.alt-color-black {
		& b {
			background-color: var(--tp-common-black);
		}
	}
	&.alt-color-white {
		& b {
			background-color: var(--tp-common-white);
		}
	}
}
.tp-btn-price{
	display: inline-block;
	background: rgba(89, 86, 233, 0.08);
	color: var(--tp-common-blue);
	height: 45px;
	line-height: 45px;
	border-radius: 30px;
	padding: 0 35px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: .5s;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.submit-btn{
	background-color: var(--tp-common-blue-4);
	text-align: center;
	height: 55px;
	padding: 0px 20px;
	display: inline-block;
	line-height: 55px;
	color: var(--tp-common-white);
	font-weight: 600;
	font-size: 16px;
	&:hover{
		color: var(--tp-common-white);
	}
}
.signin-btn{
	background-color: var(--tp-common-blue-4);
	text-align: center;
	height: 55px;
	padding: 0px 100px;
	display: inline-block;
	line-height: 55px;
	color: var(--tp-common-white);
	font-weight: 600;
	font-size: 16px;
	border-radius: 12px;
	&:hover{
		background-color: rgba(108, 20, 250, 1.875);
		color: var(--tp-common-white);
	}
}
.tp-btn-inner{
	display: inline-block;
	background: var(--tp-common-blue-4);
	color: var(--tp-common-white);
	height: 50px;
	line-height: 50px;
	border-radius: 30px;
	padding: 0 35px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: .5s;
	&.white-bg{
		&:hover{
			transform: translateY(-2px);
			box-shadow: 0px 8px 24px 0px rgba(25, 118, 41, 0.3);
		}
	}
	& span {
		position: relative;
		z-index: 5;
		transition: .3s;
		transition-delay: .3s;
		color: var(--tp-common-white);
		display: inline-block;
		transform: translateY(-2px);
	}
	&:hover{
		& span{
			color: var(--tp-common-white);
			transition-delay: .1s;
		}
    }
}
.tp-btn-inner-border{
	display: inline-block;
	color: var(--tp-common-white);
	height: 40px;
	line-height: 36px;
	border-radius: 30px;
	padding: 0 30px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: .5s;
	border: 1.5px solid rgba(255, 255, 255, 0.2);
	border-radius: 30px;
	& i{
		padding-left: 7px;
		transform: rotate(45deg) translateY(-3px);
	}
	&:hover{
		color: var(--tp-common-yellow-3);
	}
}